// @flow
import React from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import { connect } from 'react-redux';
import styled from 'styled-components/native';
import * as IO5 from 'react-icons/io5';
import * as FA from 'react-icons/fa'
import * as ICO from 'react-icons/bi';
import { User } from 'types';
import { 
	submitAttestation,
	checkImages, 
	fetchAttestation,
	uploadAttestationDocument,
	deleteAttestationDocument,
	downloadDocumentFile
} from 'actions/attestation';
import { showAlert } from 'actions/alert';
import { fetchLoan, createEntry, updateEntry } from 'actions/loan';
import { setNavButtons, backBtn } from 'reducers/navbar.js';
import snip from 'styles/snip.js';
import SceneCommon from 'containers/SceneCommon';
import Dialog from 'components/Dialog';
import LS from 'utils/localStore';
import colors from 'styles/colors';
import CustomCheckbox from './customCheckbox';
import RejectNoteModal from './rejectNoteModal';
import { saveAs } from 'file-saver';

const Container = styled(View)`
	flex: 1;
	padding: 12px 3px;
	background-color: #f5f5f5;
`;

const InstructionsText = styled(Text)`
	font-size: 16px;
	color: #333;
`;

const UploadedDocumentsContainer = styled(View)`
	margin-top: 12px;
	border-radius: 3px;
`;

const UploadedDocumentsTitle = styled(Text)`
	font-size: 18px;
	color: #333;
	margin-bottom: 8px;
	font-weight: bold;
`;

const DocnameText = styled(Text)`
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	color: black;
	background-color:#ececec;
	padding: 10px;
	margin-bottom: 8px;
	margin-right: 8px;
	border-radius: 3px;
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
	max-width:90%;
	flex: 1;
`;

const UploadButton = styled(TouchableOpacity)`
	background-color: ${colors.evp_blue};
	padding: 12px;
	border-radius: 3px;
	align-items: center;
`;

const UploadButtonText = styled(Text)`
	color: white;
	font-size: 16px;
`;

const ButtonContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
`;

const Button = styled.TouchableOpacity`
background-color: ${({ disabled }) => (disabled ? 'gray' : `${colors.evp_blue}`)};
padding: 10px;
border-radius: 5px;
opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const ButtonText = styled.Text`
  font-size: 17px;
  color: #fff;
  text-align: center;
`;

type Props = {
	currentUser: User,
	inspEntries: Array<Object>,
};

type State = {
	additionalDocuments: Array<Object>,
};

class UploadDocuments extends React.Component<Props, State> {
	refFileInputDocument: { current: null | HTMLInputElement };

	constructor(props: Props) {
		super(props);
		this.refFileInputDocument = React.createRef();
		this.state = {
			additionalDocuments: [],
			userConsentAgree:false,
			activePage:'DocumentsUpload',
			rejectNote:'',
			rejectModalVisible:false,
		};
	}

	navBack = () => {
		this.props.history.push('/attestations');
	};

	componentWillUnmount() {
		this.props.setNavButtons(null);
	}

	componentDidMount() {
		this.initialize();
	}

	backToDocumentsUpload = () =>{
		this.setState({activePage:'DocumentsUpload'}, ()=>{
			this.initialize();
		})
	}

	navigateToUserConsentPage = () => {
		const { setNavButtons } = this.props;
		this.setState({activePage : 'UserConsent'}, ()=>{
			setNavButtons([
				backBtn(() => this.backToDocumentsUpload() , 'Back')
			])
		})

	}

	initialize = () => {
		const { setNavButtons, onChangePage, attestation, currentUser } = this.props;
		const backButton = backBtn(() => onChangePage('ShowAttestation'), 'Back');
		const determineNavButtons = () => {
			const baseButtons = [backButton];
			const additionalButton = {
				label: 'Next',
				action: this.navigateToUserConsentPage,
				iconName: 'ios-arrow-forward'
			};
			if (attestation.inspector_id === currentUser.id && attestation.status === 'pending') {
				return [...baseButtons, additionalButton];
			}
			else if (attestation.attester_id === currentUser.id && attestation.status === 'attest') {
				return [...baseButtons, additionalButton];
			}
			return baseButtons;
		};
		setNavButtons(determineNavButtons());

		return LS.Get('attestations', attestation.id)
		.then((attest) => {
			if (!attest)
				return Promise.reject({ message: 'attestations not found' });

			if (Array.isArray(attest.documents)) {
				this.setState({ additionalDocuments: attest.documents });
			}

			return Promise.resolve(attest);
		});
	};

	handleDocumentPick = (evt) => {
		const arFiles = evt.target.files;
		const { showAlert, uploadAttestationDocument, attestation, currentUser } = this.props;

		if (arFiles.length === 0) {
			showAlert('No files selected', 'Error', 'error');
			return;
		}

		if (arFiles.length > 1) {
			showAlert('Too many files selected', 'Error', 'error');
			return;
		}

		if (!arFiles[0].type.match('application/pdf')) {
			showAlert('Please select a PDF file', 'Error', 'error');
			return;
		}

		return uploadAttestationDocument(attestation.id, currentUser.id, arFiles[0], arFiles[0].name)
		.then(() => {
			this.initialize();
		});
	};

	removeDocument = (document) => {
		const { deleteAttestationDocument, attestation, currentUser } = this.props;

		let decDetails = { 
			is_temp_doc: document.is_temp || false,
			document_id: document.id,
		};

		return deleteAttestationDocument(attestation.id, currentUser.id, decDetails)
		.then(() => {
			this.initialize();
		});
	};

	downloadFileForReview = (document) =>{
		const { downloadDocumentFile, showAlert } = this.props;
		const {user_id, attestation_id, id, file} = document;
		if (user_id && attestation_id && id) {
			downloadDocumentFile(user_id, attestation_id, id)
			.then((rsp) => {
				const blob = new Blob([rsp], { type: 'application/pdf' });
        		saveAs(blob, file);
			})
			.catch((err) => {
				let error_message = err.error ? err.error : 'Failed to load the document';
				showAlert(error_message, 'Error', '')
			})
		}
		else{
			showAlert('Failed to download the document, please try again later', 'Error', '')
		}
	}

	handleUserConsent = (consentBool) =>{
		let { userConsent } = consentBool;
		const { setNavButtons } = this.props;
		this.setState({userConsentAgree : userConsent},()=>{
			setNavButtons([
				backBtn(() => this.backToDocumentsUpload() , 'Back')
			])
		});
	}

	handleRejectAttestation = () =>{
		this.setState({rejectModalVisible:true});
	}

	handleCloseModal = (modalData) =>{
		const {rejectNote, saveAndReject} = modalData;
		const rejectAndCloseModal = (rejectNote) =>{
			const {handleReject} = this.props;
			handleReject(rejectNote);
			this.setState({rejectNote:"", rejectModalVisible:false })
		}
		saveAndReject ? rejectAndCloseModal(rejectNote) : this.setState({rejectNote:rejectNote, rejectModalVisible:false });
	}

	render() {
		const { additionalDocuments, userConsentAgree, activePage, rejectModalVisible, rejectNote } = this.state;
		const { attestation, isAttesterReviewMode, handleSubmit } = this.props;
		let attestMode = attestation.status === 'attest' && isAttesterReviewMode;
		let allowUploadingDocuments = attestation.status === 'pending' && !isAttesterReviewMode;
		let consentMessage = "By checking this box, I am digitally signing this document and consenting to the use of my electronic signature in lieu of a handwritten signature."

		return (
			<React.Fragment>
				<Dialog
					visible={false}
					message={`Large images can take a while to transfer. Try submitting your attestation again in a few minutes.`}
					onAccept={() => {}}
				/>
				<SceneCommon style={{ padding: snip.pad }}>
					{ activePage === "DocumentsUpload" &&
					<Container>
                        {allowUploadingDocuments &&
							<InstructionsText>
							Uploading documents is optional. Use the button below if you wish to add files.
							</InstructionsText>
						}
						{(!allowUploadingDocuments && additionalDocuments.length < 1) &&
							<InstructionsText>
								No documents found.
							</InstructionsText>
						}
						{additionalDocuments.length > 0 &&
                        <UploadedDocumentsContainer>
							<UploadedDocumentsTitle>
                                Uploaded Documents
                            </UploadedDocumentsTitle>
							<FlatList
								data={additionalDocuments}
								keyExtractor={(item, index) => `${index}_for_the_doc_${item.file}`}
								renderItem={({ item, index }) => (
									<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
										<DocnameText style={{ boxShadow:'0 2px 4px rgba(0, 0, 0, 0.1)'}}>
											<ICO.BiSolidFilePdf style={{ fontSize: 27 }} />
											<Text style={{ marginLeft: 7 }}>{item.file}</Text>
										</DocnameText>
										{allowUploadingDocuments &&
										<TouchableOpacity onPress={() => this.removeDocument(item)}>
											<View>
												<IO5.IoTrash style={{ color: `${colors.red}`, fontSize: 27, marginLeft: 5 }} />
											</View>
										</TouchableOpacity>
										}
										{attestMode &&
										<TouchableOpacity onPress={() => this.downloadFileForReview(item)}>
											<View>
												<FA.FaDownload style={{ color: `${colors.evp_blue}`, fontSize: 27, marginLeft: 5 }} />
											</View>
										</TouchableOpacity>
										}
									</View>
								)}
							/>
						</UploadedDocumentsContainer>
                        }
						<View style={{ minHeight: 50, marginTop: 16 }}>
							<input
								type="file"
								accept="application/pdf, .pdf"
								ref={this.refFileInputDocument}
								onChange={this.handleDocumentPick}
								style={{ display: 'none' }}
							/>
							{allowUploadingDocuments &&
							<UploadButton onPress={() => this.refFileInputDocument.current.click()}>
								<UploadButtonText>{"Choose File (Optional)"}</UploadButtonText>
							</UploadButton>
							}
						</View>
					</Container>
					}
					{ activePage === "UserConsent" &&
					<Container>
						<View style={{marginBottom:'2.8rem'}}>
							<UploadedDocumentsTitle>
								Digital Signature Confirmation
                            </UploadedDocumentsTitle>
							<CustomCheckbox
								key={`userconsent`}
								label={consentMessage}
								checked={userConsentAgree}
								onPress={this.handleUserConsent}
							/>
						</View>
						<ButtonContainer>
							{ attestMode &&
								<Button
								style={{backgroundColor:`${colors.red}`, flex:0.48}}
									onPress={this.handleRejectAttestation}
								>
									<ButtonText>{"Reject"}</ButtonText>
								</Button>
							}
							<Button
								disabled={!userConsentAgree}
								onPress={!userConsentAgree ? null : handleSubmit}
								style={{ flex: attestMode ? 0.48 : 1 }}
							>
								<ButtonText>{"Submit"}</ButtonText>
							</Button>
						</ButtonContainer>
					</Container>
					}
					{ rejectModalVisible &&
						(
							<View>
								<RejectNoteModal
								visible={rejectModalVisible}
								onRequestClose={this.handleCloseModal}
								body={rejectNote}
								/>
							</View>
						)
					}
				</SceneCommon>
			</React.Fragment>
		);
	}
}

export default connect(
	(state) => ({
		currentUser: state.session.currentUser,
		inspEntries: state.loan.inspEntries,
	}),
	{
		showAlert,
		fetchAttestation,
		submitAttestation,
		setNavButtons,
		fetchLoan,
		createEntry,
		updateEntry,
		checkImages,
		uploadAttestationDocument,
		deleteAttestationDocument,
		downloadDocumentFile
	}
)(UploadDocuments);
